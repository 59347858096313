import React from 'react';

import {
    useI18next,
} from "gatsby-plugin-react-i18next"

import styled from "styled-components"

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import Quiz from './quiz-cultural-awareness';

import { ContainerWithBackground as Container } from '../styles/global-styles'
import backgroundImage from '../images/background-test-yourself.jpg';

// const Container = styled.div`
//     width: 100%;
//     // padding-top: 60px;
//     text-align: center;
//     // background: #ACD433;
//     // background: #2c466b;
//     // color: wheat;
//     // margin-top: 15px;
//     margin: 0 auto;
// `

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props => props.$backgroundColour};
    color: ${props => props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
`

const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;
    
    // background: white;
    padding: 32px;
        
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    max-width: 90%;
    max-width: 1372px;
    background: white;
    
    p { 
        
        font-size: 20px;
        line-height: 1.4;
        margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
    }
`

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`



const TestYourCulturalAwarenessContent = () => {
    const { t } = useI18next();

    return (
        <Container backgroundImage={backgroundImage}>
            <ContentWrapper >
                <SectionHeader title={t("TEST-YOURSELF.TEST-YOUR-CULTURAL-AWARENESS")}  withBackground={false}/>
                <Content>
                    <ContentInner>
                        <Quiz />
                    </ContentInner>
                </Content>
            </ContentWrapper>
        </Container>
    )
}

export default TestYourCulturalAwarenessContent