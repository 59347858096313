import React, { useState, useEffect } from 'react';
import {
    Link,
    useI18next,
    I18nextContext
} from "gatsby-plugin-react-i18next"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components"
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Divider from '@material-ui/core/Divider';

import QuestionCard from './question-card';


// const TOTAL_QUESTIONS = 10

const questionsArray = [
    {
        question: `You sit down in an almost empty movie theatre. Two people come in after you, a Dutch and an Egyptian person. Who is more likely to sit down beside you?`,
        answers: ['A - the Dutch', 'B - the Egyptian'],
        correctAnswer: 'B - the Egyptian',
        explanation: `Each culture has its own “personal space” definition. Hanseatic cultures, like the Dutch, prefer a wide personal space, Arabic cultures, like the Egyptian, prefer closeness. Respecting personal space is very important. Be aware that your own preferences may not be the same as the other persons. Do not “invade” be getting too close and do not be “aloof” by standing too far.`
    },
    {
        question: `You are discussing a new project with two colleagues - one form Germany, the other from the US. You ask them for their “guesstimate” of project timing. Who is more likely to give you one?`,
        answers: ['A - the American', 'B - the German'],
        correctAnswer: 'A - the American',
        explanation: `Managers from less formal Anglo-Saxon business cultures have no problem giving you a “best guess”. The more well defined Germanic cultures require that you give an answer only when you actually have a precise answer. No “guessing”. In addition, if it is not within the person’s authority to give the answer, they won’t. So do not take it as an insult when someone from another culture refers you to someone else for an answer, even if they know it (and you know that they do).`
    },
    {
        question: `You are negotiating with Czech and a Malaysian suppliers. In your separate meetings, they both avoid looking into your eyes. Based on this signal alone, which one would you trust more?`,
        answers: ['A - the Czech', 'B - the Malaysian'],
        correctAnswer: 'B - the Malaysian',
        explanation: `In many Far Eastern cultures looking into your eyes is impolite, while looking away is a sign of respect. In most European cultures looking away is a signal of your covering up something, not being fully honest.`
    },
    // {
    //     question: `In a discussion you are about to say: “Your country is where it’s at, as a result of how you people managed your affaires in the past.” Your discussion partner is either Canadian or Hungarian. Which one you should definitely NOT say this to?`,
    //     answers: ['A - the Canadian', 'B - the Hungarian'],
    //     correctAnswer: 'B - the Hungarian',
    //     explanation: `Canadian culture encourages everyone to take personal responsibility for their fate, and collective responsibility for the country’s future. Hungarian culture is very open towards giving up personal or collective responsibility for any action. This also means not taking responsibility for the eventual outcome of those same actions.`
    // },
    {
        question: `Preparing for an international meeting, you have to brief the Hungarian and Finnish participants. Which one expects a detailed, step-by-step explanation?`,
        answers: ['A - the Finnish', 'B - the Hungarian'],
        correctAnswer: 'A - the Finnish',
        explanation: `Nordic cultures are “low context” - they rely on detailed, explicit communication of information. Southern cultures (as well as the Hungarian) tend to be “high context” - they assume you already have the background understanding to the core information communicated. When in doubt, use the “low context” method. High context people understand low context communication, but low context recipients have problem interpreting high context messages.`
    },
    {
        question: `Which delegation is more likely to be on time for your meeting?`,
        answers: ['A - the Austrian', 'B - the Spanish'],
        correctAnswer: 'A - the Austrian',
        explanation: `Time perception also differs among cultures. Latin / Southern cultures have a less rigid time definition. Northern / Germanic / Anglo-Saxon cultures have more exact time perception. Being late for a meeting in Germany is insulting to your hosts. But you should expect the Spaniards to be late for your meetings - and this does NOT mean they want to insult you!`
    },
    {
        question: `You are about to say: “Please take a look at these numbers. They are black and white, so no discussion is needed.” Which participants are still likely to want a discussion?`,
        answers: ['A - the Swiss', 'B - the French'],
        correctAnswer: 'B - the French',
        explanation: `In Gallic cultures intellectual engagement (discussions) are highly valued. While people from many other cultures accept facts without argument, even in the case of agreement you need to be prepared to engage your Gallic counterparts in an intellectually stimulating debate.`
    },
    {
        question: `After your meeting has ended, you promise to send the written material discussed in about a week. Which delegation might have a problem with this?`,
        answers: ['A - the Romanian', 'B - the Japanese'],
        correctAnswer: 'B - the Japanese',
        explanation: `In Japanese business etiquette it is expected that you provide a written summary as a take-away at the end of the meeting. No such requirements exist in Southern Europe. In addition, the inexact “in about a week” is well tolerated in Southern cultures, but not in the Japanese one.`
    },
    {
        question: `Your company has just signed two written contracts. One with a Bulgarian partner, one with a Chinese one. With which partner do you need to allocate more time for follow up issues?`,
        answers: ['A - the Bulgarian', 'B - the Chinese'],
        correctAnswer: 'B - the Chinese',
        explanation: `While some issues may generate follow up need in Bulgaria, they are explicit, predictable issues. Cultural differences are more serious. In Western cultures a signed, written contract is the closure of the negotiations and governs how the signing parties will behave. In Chinese cultures a written contract is not the end of the negotiating process, but the beginning of it. You may expect your partners to keep wanting to modify it, even though you think it is already a done deal.`
    },
    // {
    //     question: `You have some carpentry work done in your home by different tradesmen. Which one could you offer payment tax free, “under the table”, without potentially insulting him?`,
    //     answers: ['A - the Italian', 'B - the British'],
    //     correctAnswer: 'A - the Italian',
    //     explanation: `While individual honesty may vary, the “grey economy” is culturally acceptable in Italy but not so in the UK. It is therefore less likely that you insult your Italian carpenter by the offer, even if he declines it.`
    // },
]

const questionsArrayHU = [
    {
        question: `Belép és leül egy szinte üres moziban. Ketten, egy holland és egy egyiptomi néző érkezik Ön után. Melyikük ül le nagyobb valószínűséggel Ön mellé?`,
        answers: ['A – a holland', 'B – az egyiptomi'],
        correctAnswer: 'B – az egyiptomi',
        explanation: `Minden kultúrában másként értelmezik a “privát szférát”. A német típusú kultúrákban inkább a távolságtartás a jellemző, míg az arab kultúrákban közelség az általános. Az adott ember “privát szférájának” tiszteletben tartása fontos: sem a túlságosan tolakodó (közel lépő), sem a túlzottan távolságtartó (távol megálló) magatartás nem szerencsés.`
    },
    {
        question: `Egy német és egy amerikai kollégájával egy projekttervet készít. Melyikük fog nagyobb valószínűséggel egy becslést adni az ütemezésre vonatkozóan?`,
        answers: ['A – az amerikai', 'B – a német'],
        correctAnswer: 'A – az amerikai',
        explanation: `Az angolszász kultúrák általában lazábbak, az onnan származó vezetők jellemzően könnyen adnak becsléseket. A precízebb német kultúrában többnyire nem a becslés, csak a precíz válasz az elfogadott. Emellett még az is megjelenik szempontként, hogy az adott kolléga rendelkezik-e megfelelő hatáskörrel. Ne vegye rossz néven, ha egy másik kultúrából származó kolléga akkor is máshoz irányítja Önt egy kérdéssel, ha egyébként tudná a választ.`
    },
    {
        question: `Egy beszerzési eljárás során, egy tárgyaláson vesz részt egy cseh, illetve egy malajziai beszállítóval. A tárgyalás során mindketten kerülik a szemkontaktust. Melyikükben bízna meg jobban?`,
        answers: ['A – a cseh partnerben', 'B – a malajziai partnerben'],
        correctAnswer: 'B – a malajziai partnerben',
        explanation: `A legtöbb távol-keleti kultúrában udvariatlanság a másik szemébe nézni, míg a szemkontaktus kerülése a tisztelet jele. Ezzel szemben az európai kultúrák többségében ha a tárgyaló partner kerüli a szemkontaktust, az annak a jele, hogy valamit rejteget.`
    },
    // {
    //     question: `In a discussion you are about to say: “Your country is where it’s at, as a result of how you people managed your affaires in the past.” Your discussion partner is either Canadian or Hungarian. Which one you should definitely NOT say this to?`,
    //     answers: ['A - the Canadian', 'B - the Hungarian'],
    //     correctAnswer: 'B - the Hungarian',
    //     explanation: `Canadian culture encourages everyone to take personal responsibility for their fate, and collective responsibility for the country’s future. Hungarian culture is very open towards giving up personal or collective responsibility for any action. This also means not taking responsibility for the eventual outcome of those same actions.`
    // },
    {
        question: `Egy nemzetközi megbeszélés előkészítése során briefeli az egyik magyar, illetve finn kollégáját. Melyikük várja el részletekbe menő információkat?`,
        answers: ['A – a finn', 'B – a magyar'],
        correctAnswer: 'A – a finn',
        explanation: `Az északi kultúrák jellemzően “alacsony kontextus” kultúrák, azaz azt feltételezik, hogy a másik fél kevés háttérinformáció birtokában van. Ezzel szemben a magyar kultúrában, illetve a deli kultúrákban a “magas kontextus” a jellemző, azaz abból indulnak ki, hogy a szükséges alapinformációk mindenkinek rendelkezésre állnak. Ha nem egyértelmű, hogy melyikkel állunk szemben érdemes “alacsony kontextus”-nak megfelelően kommunikálni, mert a “magas kontextus”-ú partnerek megértik az “alacsony kontextusú” információt, de ez fordítva nem igaz.`
    },
    {
        question: `Melyik tárgyalófél fog jellemzően időben érkezni a tárgyalásra?`,
        answers: ['A – az osztrák', 'B – a spanyol'],
        correctAnswer: 'A – az osztrák',
        explanation: `A különböző kultúrák eltérően tekintenek az időre. A latin / déli kultúrák lazábbak az idő kezelésében, míg az északi / angolszász / német kultúrákban a pontosság fontos. Németországban elkésni egy megbeszélésről udvariatlanságnak számít. Ugyanakkor ne lepődjön meg, ha egy spanyol vendége késve érkezik – és főleg ne vegye sértésnek.`
    },
    {
        question: `Egy beszélgetés során elhangzik a következő mondat: “Nézzük meg a számokat, az üzenet egyértelmű, nem érdemes erről tovább vitázni.” Melyik résztvevő szeretné nagyobb valószínűséggel folytatni a beszélgetést?`,
        answers: ['A – a svájci', 'B – a francia'],
        correctAnswer: 'B – a francia',
        explanation: `A francia kultúrában a egyeztetést, az aktív részvételt fontosnak tartják. Más kultúrákban, ha a tények egyértelműek, nincs szükség egyeztetésre. Ezért, ha francia partnerrel egyeztet, érdemes akkor is átbeszélni az adott témát, ha egyébként a számok alapján a helyzet és a következtetés, döntés is egyértelmű.`
    },
    {
        question: `Egy megbeszélés zárásaként megígéri, hogy egy héten belül megküldi az összefoglalót. Melyik tárgyalófél fogja ezt nagyobb valószínűséggel nehezményezni?`,
        answers: ['A – a román', 'B – a japán'],
        correctAnswer: 'B – a japán',
        explanation: `A japán üzleti etikett szerint az összefoglalót a megbeszélés végén meg kell kapják a résztvevők. Dél-Európában ez nem jellemző és a nagyjábóli határidő (egy héten belül) is elfogadható.`
    },
    {
        question: `Az elmúlt héten egy bolgár, illetve egy kínai partnerrel kötött szerződést. Melyik esteben kell számítani több utánkövetésre?`,
        answers: ['A – a bolgár partner esetén', 'B – a kínai partner esetén'],
        correctAnswer: 'B – a kínai partner esetén',
        explanation: `Adódhatnak problémák, amelyek miatt szükség lehet az utánkövetésre, Bulgáriában ezek jellemzően előre tervezhetőek. A kulturális különbségek kezelése ennél sokkal nehezebb. A nyugati kultúrákban a szerződés aláírása a tárgyalás lezárását jelenti, és innentől kezdve egyértelmű a felek viselkedése. Ezzel szemben a kínai kultúrában a szerződés aláírása nem a tárgyalások lezárása, sokkal inkább a kezdete. Ne legyen meglepve, ha kínai partnere a szerződés aláírását követően javaslatokat tesz annak megváltoztatására.`
    },
    // {
    //     question: `You have some carpentry work done in your home by different tradesmen. Which one could you offer payment tax free, “under the table”, without potentially insulting him?`,
    //     answers: ['A - the Italian', 'B - the British'],
    //     correctAnswer: 'A - the Italian',
    //     explanation: `While individual honesty may vary, the “grey economy” is culturally acceptable in Italy but not so in the UK. It is therefore less likely that you insult your Italian carpenter by the offer, even if he declines it.`
    // },
]


const Instructions = styled.div`
    && {
        margin-bottom: 40px;
        opacity: 1;
        // background: #2c466b;
        padding: 10px 20px;
        // max-height: 105px;
        
                
          @media screen and (max-width: 1220px) {
              max-height: initial;
              width: 100%;
          }
        
        p {
            font-size: 20px;
            // color: white;
            text-align: center;

            &.preamble {
                margin-top: 0;
                margin-bottom: 0;
            }

            &.disclaimer {
                font-size: 18px;
            }
        }
        
    }

    
    &.hidden {
        opacity: 0;
        max-height: 0;
        margin: 0;
        padding: 0;
        visibility: hidden;
        transition: all 1s ease;
    }
`
const YourScore = styled.p`
    && {
        margin-top: 24px;
        font-size: 42px;
        text-align: center;
        
        @media screen and (max-width: 1220px) {
            margin-top: 0;
            font-size:24px;
        }
    }
`

const MoreInformation = styled.div`
    a {
        color: #ff8000;
    }
`

const ResetButtonWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    &::after {
        content: attr(data-reset);
        position: absolute;
        top: -18px;
        left: -6px;
        visibility: hidden;
        transition: all 0.1s ease;
        opacity: 0;

    }

    &.hu::after {
        left: -19x;
    }

    &:hover {
        &::after {
            visibility: visible;
            opacity: 1;
        }
    }
`

const QuizCulturalAwareness = () => {

    const context = React.useContext(I18nextContext);
    const language = context.language;

    const { t } = useI18next();

    const [questions, setQuestions] = useState((language === "hu") ? questionsArrayHU : questionsArray);
    const [number, setNumber] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [score, setScore] = useState(0);
    const [gameOver, setGameOver] = useState(true);

    useEffect(() => {
        // startGame()
    }, [])



    function startGame() {
        setGameOver(false);
    }

    function checkAnswer(e) {
        const userAnswer = e.currentTarget.value;
        const correctAnswer = questions[number].correctAnswer.toString();

        const isCorrect = correctAnswer === userAnswer;

        if (isCorrect) setScore((prev) => prev + 1);

        const answerObject = {
            question: questions[number].question,
            answers: questions[number].answers,
            correctAnswer: questions[number].correctAnswer,
            explanation: questions[number].explanation,
            userAnswer,
            isCorrect,
        };

        setUserAnswers((prev) => [...prev, answerObject]);

        // const nextQ = number + 1;
        // if (nextQ === questions.length) {
        //   setGameOver(true);
        //   console.log("Game over")
        // } 
    }

    function nextQuestion() {
        const nextQ = number + 1;
        if (nextQ === questions.length) {
            setGameOver(true);
        } else {
            setNumber(nextQ);
        }
    }

    function reset() {
        setNumber(0)
        setUserAnswers([])
        setScore(0)
        setGameOver(false)
    }

    return (
        <div style={{position: 'relative'}}>
            { gameOver && (number == 0) ? (
                <Box>
                    <Instructions className={userAnswers[number] ? "hidden" : null}>
                        <p className="preamble">{t("TEST-YOURSELF.PREAMBLE", { numberOfQuestions: questions.length })}</p>
                        <p className="disclaimer"><i>{t("TEST-YOURSELF.CULTURAL-QUIZ-DISCLAIMER")}</i></p>
                    </Instructions>
                    <Box mt={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<NavigateNextIcon />}
                            className='next'
                            onClick={startGame}
                        >
                            {t("TEST-YOURSELF.START-THE-QUIZ")}
                        </Button>
                    </Box>
                </Box>
            ) : null}

            {
                !gameOver ? (
                    <h3>{t("TEST-YOURSELF.QUESTION")}: {number + 1} / {questions.length}</h3>
                ) : null
            }

            {
                !gameOver ? (
                    <QuestionCard
                        questionNumber={number + 1}
                        totalQuestions={questions.length}
                        question={questions[number].question}
                        answers={questions[number].answers}
                        userAnswer={userAnswers ? userAnswers[number] : undefined}
                        explanation={questions[number].explanation}
                        callBack={checkAnswer}
                        correctAnswer={questions[number].correctAnswer}
                        wider={true}
                    />) : null
            }



            {!gameOver && userAnswers.length === number + 1 && number <= questions.length - 1 ? (
                <Box mt={5}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<NavigateNextIcon />}
                        className='next'
                        onClick={nextQuestion}
                    >
                        {number + 1 < questions.length ? t("TEST-YOURSELF.NEXT-QUESTION") : t("TEST-YOURSELF.GET-RESULT")}
                    </Button>
                </Box>
            ) : null}

            { gameOver && userAnswers.length > 0 && (
                <div>
                    <div>
                        <YourScore>{t("TEST-YOURSELF.YOUR-SCORE")}: {score} / {questions.length}</YourScore>
                    </div>
                    <Divider />

                    { (language === "en") &&
                        <MoreInformation>
                            <p>Ask CodeBreakers how you can use cultural understanding and cultural archetypes to cooperate better with people from different backgrounds. <Link to="/contact">Contact us</Link> for details.</p>
                            <p>If you would like to learn more on the topic, {/*see our <Link to="/publications/articles-white-papers">White Papers</Link>, or*/} read the following books:</p>
                            <p><b><AnchorLink to="/publications/books#the-culture-code-an-ingenious-way-to-understand-why-people-around-the-world-live-and-buy-as-they-do">THE CULTURE CODE</AnchorLink></b> by Clotaire Rapaille</p>
                            <p><b>SMALL DATA</b> by Martin Lindstrom</p>
                            <p>Any <AnchorLink to="/publications/books#beyond-culture">book</AnchorLink> by Edward T. Hall</p>
                        </MoreInformation>
                    }
                    { (language === "hu") &&
                        <MoreInformation>
                            <p>A CodeBreakers csapat segít a kulturális különbségek megértésében és a multikulturális helyzetek kezelésében. További információkért lépjen kapcsolatba velünk a <Link to="/contact">Kapcsolat</Link> menüben található elérhetőségeinken. </p>
                            <p>Ha többet szeretne tudni a témáról, az alábbi könyveket ajánljuk figyelmébe: </p>
                            <p><b><AnchorLink to="/hu/publications/books#the-culture-code-an-ingenious-way-to-understand-why-people-around-the-world-live-and-buy-as-they-do">THE CULTURE CODE</AnchorLink></b> (Clotaire Rapaille)</p>
                            <p><b>SMALL DATA</b> (Martin Lindstrom)</p>
                            <p>Edward T. Hall <AnchorLink to="/hu/publications/books#beyond-culture">könyvei</AnchorLink></p>
                        </MoreInformation>
                    }

                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<RotateLeftIcon />}
                        className='next'
                        onClick={reset}
                    >
                        {t("TEST-YOURSELF.RESET-QUIZ")}
                    </Button>
                </div>
            )}
            {
                !gameOver &&
                <ResetButtonWrapper data-reset={t("TEST-YOURSELF.RESET")} className={(language === "hu") && "hu"}>
                    <RotateLeftIcon onClick={reset} />
                </ResetButtonWrapper>
            }

        </div>
    )
}

export default QuizCulturalAwareness