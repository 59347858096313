import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TestYourCulturalAwarenessContent from "../../components/test-your-cultural-awareness-content"



const TestYourCulturalAwarenessPage = () => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.TEST-YOUR-CULTURAL-AWARENESS")} />
      <TestYourCulturalAwarenessContent />
    </Layout>
  )
}


export default TestYourCulturalAwarenessPage